import React, { Component } from 'react'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { FaTwitter, FaDiscord, FaHackerNews } from 'react-icons/fa'
import kubesailHeart from '../../assets/heart-icon-large.png'
import { apiFetch } from '../../util'

const OutlineLogo = dynamic(() => import('../../icons').then(i => i.OutlineLogo))

export default class Footer extends Component {
  state = {
    blogPosts: null,
  }

  componentDidMount = async () => {
    if (!this.props.blogPosts) {
      const {
        json: { posts },
      } = await apiFetch('/blog-posts', { query: { limit: 5 } })
      this.setState({ blogPosts: posts })
    }
  }

  generateBlogHref(filename) {
    return `/blog/${filename.replace(/\.md$/, '')}`
  }

  render() {
    const blogPosts = this.state.blogPosts || this.props.blogPosts || []

    return (
      <div className="Home-Footer">
        <OutlineLogo style={{ width: '150px' }} />
        <div className="content">
          <div className="section">
            <Link href={'/blog'} prefetch={false} passHref>
              <h4 style={{ cursor: 'pointer', textDecoration: 'underline' }}>Blog</h4>
            </Link>
            <ul>
              {blogPosts.map(post => {
                return post.metadata.published ? (
                  <li key={post.metadata.filename}>
                    <Link
                      onClick={() => window.scrollTo(0, 0)}
                      href={this.generateBlogHref(post.metadata.filename)}
                      prefetch={false}
                    >
                      {post.metadata.title}
                    </Link>
                  </li>
                ) : null
              })}
            </ul>
          </div>
          <div className="section">
            <h4>KubeSail</h4>
            <Link prefetch={false} href="/homepage">
              Homepage
            </Link>
            <Link prefetch={false} href="/training">
              DevOps Training
            </Link>
            <Link prefetch={false} href="/templates">
              Browse Templates
            </Link>
            <Link href="/template/new">YAML Generator</Link>
          </div>
          <div className="section">
            <h4>Tools & Legal</h4>
            <a rel="noreferrer nofollow" target="_blank" href="https://docs.kubesail.com/">
              Docs
            </a>
            <div>
              <Link prefetch={false} href="/privacy">
                Privacy Policy
              </Link>
            </div>
            <div>
              <Link prefetch={false} href="/terms">
                Terms of Service
              </Link>
            </div>
          </div>
          <div className="section">
            <h4>Company</h4>
            {/* <Link href="">About Us</Link> */}
            <div>
              <a rel="noreferrer nofollow" target="_blank" href="https://twitter.com/kubesail">
                <FaTwitter /> @kubesail
              </a>
            </div>
            <div>
              <a rel="noreferrer nofollow" target="_blank" href="https://discord.gg/N3zNdp7jHc">
                <FaDiscord /> Community Chat
              </a>
            </div>
            <div>
              <a rel="noreferrer nofollow" target="_blank" href="mailto:support@kubesail.com">
                support@kubesail.com
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/kubesail">
                Schedule a Meeting
              </a>
            </div>
          </div>
        </div>
        <div className="heart" title="Made with love in California and Texas">
          <Image
            src={kubesailHeart}
            width={25}
            height={25}
            alt="Made with love in California and Texas"
          />
          <a
            href="https://ycombinator.com"
            target="_blank"
            rel="noreferrer nofollow"
            title="KubeSail is a YCombinator company"
          >
            <FaHackerNews className="yc" />
          </a>
        </div>
      </div>
    )
  }
}
